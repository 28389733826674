import React, { useEffect, useMemo, useRef, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, fileImport, academics, academicexporturl } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../services/fetchData";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";
import { showNormalToast } from "../../../services/toaster/NormalToaster";

const AcademicChannelsList = () => {
  const breadcrumbItems = [];
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const createFileInputRef = useRef(null);
  const updateFileInputRef = useRef(null);
  const [issAllDeleteToastOpen, setIsAllDeleteToastOpen] = useState(false);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, [searchKeyword]);

  const getData = async () => {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      const payload = {
        modelName: "academic_channels",
        search: {
          field_name: ["channel_title", "summary", "speaker_name"],
          searchKeyword: searchKeyword,
        },
        whereCondition: {
          type: 1,
          is_deleted: 0,
        },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/academic-channels-create");
  };

  const handleEditClick = (id) => {
    navigate(`/academic-channels-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;

    const payload = {
      type: "Channel",
      id: id,
      confirmDelete: false,
    };
    const responseData = await fetchData(
      "academicContentDelete",
      payload,
      academics
    );
    if (responseData && responseData.code == 1) {
      if (responseData.isHavingModuleAndPost) {
        showNormalToast(responseData.message);
        isToastOpenRef.current = false;
      } else {
        const onConfirm = async () => {
          const payload = {
            type: "Channel",
            id: id,
            confirmDelete: true,
          };
          const responseData1 = await fetchData(
            "academicContentDelete",
            payload,
            academics
          );
          if (responseData1 && responseData1.code === 1) {
            toast.success(responseData1.message);
            getData();
          } else {
            toast.error("Error while deleting the channel.");
          }
          isToastOpenRef.current = false;
        };

        const onCancel = () => {
          toast.info("Deletion cancelled");
          isToastOpenRef.current = false;
        };

        const onClose = () => {
          isToastOpenRef.current = false;
        };

        showConfirmationToast(
          responseData.message,
          onConfirm,
          onCancel,
          onClose
        );
      }
    } else {
      toast.error("Error while deleting the channel.");
    }
  };

  const handleCreateRecord = async () => {
    // setIsModalOpen(false);
    createFileInputRef.current.click();
  };

  const handleUpdateRecord = async () => {
    //  setIsModalOpen(false);
    updateFileInputRef.current.click();
  };

  const handleCreateRecordFileChange = async (event) => {
    const file = event.target.files[0];
    const validFileExtensions = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file && !validFileExtensions.includes(file.type)) {
      toast.error("Wrong file type! Please choose Only Excel file");
      createFileInputRef.current.value = "";
    } else {
      storeCreateRecordFileData(file);
    }
  };

  const handleUpdateRecordFileChange = async (event) => {
    const file = event.target.files[0];
    const validFileExtensions = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file && !validFileExtensions.includes(file.type)) {
      toast.error("Wrong file type! Please choose Only Excel file");
      updateFileInputRef.current.value = "";
    } else {
      storeUpdateRecordFileData(file);
    }
  };

  const storeCreateRecordFileData = async (file) => {
    setIsModalOpen(false);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const responseData = await fileImport(
        "academicbulkupload/1/true",
        formData,
        academics
      );

      if (responseData == 1) {
        toast.success("File insert successfully!");
        getData();
      } else {
        toast.error("Error insert file.");
      }
    } catch (error) {
      toast.error("Error insert file.");
    }
  };

  const storeUpdateRecordFileData = async (file) => {
    setIsModalOpen(false);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const responseData = await fileImport(
        "academicbulkupload/1/false",
        formData,
        academics
      );

      if (responseData == 1) {
        toast.success("File update successfully!");
        getData();
      } else {
        toast.success("File update successfully!");
      }
    } catch (error) {
      toast.success("File update successfully!");
    }
  };

  const handleExportClick = async () => {
    const url = academicexporturl + "academicexport/1";
    window.open(url, "_blank");
    toast.success("File exporting Successfully.");
  };

  const handleDeleteAllData = async () => {
    if (issAllDeleteToastOpen) return;
    setIsAllDeleteToastOpen(true);
    try {
      const onConfirm = async () => {
        const payload = {
          type: 1,
        };
        const responseData1 = await fetchData(
          "deleteacademicorcontent",
          payload,
          "academics/"
        );
        if (responseData1 && responseData1.code === 1) {
          toast.success(responseData1.message);
          getData(); // Refresh data after deletion
        } else {
          toast.error("Error while deleting the channel.");
        }
        setIsAllDeleteToastOpen(false);
      };

      const onCancel = () => {
        toast.info("Deletion cancelled");
        setIsAllDeleteToastOpen(false);
      };

      const onClose = () => {
        setIsAllDeleteToastOpen(false);
      };

      showConfirmationToast(
        "This action will permanently delete all academy data. This process is irreversible. Are you sure you want to continue?",
        onConfirm,
        onCancel,
        onClose
      );
    } catch (error) {
      console.log("error", error);
      setIsAllDeleteToastOpen(false);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },
      {
        Header: "Channel Title",
        accessor: (row) =>
          row.channel_title && row.channel_title ? row.channel_title : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "300px" }}>{value}</div>,
      },
      {
        Header: "Speaker Group Name",
        accessor: (row) =>
          row.speaker_name && row.speaker_name ? row.speaker_name : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
      },
      {
        Header: "Stock Id",
        accessor: (row) =>
          row.speaker_stock_id && row.speaker_stock_id
            ? row.speaker_stock_id
            : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value ? value : "-"),
      },
      {
        Header: "Is Shareable",
        accessor: (row) =>
          row.shareable && row.shareable ? row.shareable : "-",
        disableFilters: true,
        filterable: false,
        width: 200,
        Cell: ({ value }) => (value == 1 ? "Yes" : "No"),
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        width: 200,
        Cell: ({ value }) => (
          <div style={{ width: "100px" }}>
            {value == 0 ? "In Active" : "Active"}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        width: 100,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(false)}
          backdrop="static"
          centered={true}>
          <ModalHeader toggle={() => setIsModalOpen(false)}>
            Import Academic Data
          </ModalHeader>
          <ModalFooter
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "20px",
            }}>
            <Button
              type="button"
              style={{ backgroundColor: "#252B3B" }}
              onClick={handleUpdateRecord}>
              Update Records
            </Button>
            <input
              type="file"
              ref={updateFileInputRef}
              style={{ display: "none" }}
              onChange={handleUpdateRecordFileChange}
              accept=".xls,.xlsx"
            />
            <Button type="button" color="primary" onClick={handleCreateRecord}>
              Create Records
            </Button>
            <input
              type="file"
              ref={createFileInputRef}
              style={{ display: "none" }}
              onChange={handleCreateRecordFileChange}
              accept=".xls,.xlsx"
            />
          </ModalFooter>
        </Modal>

        <Container fluid>
          <div>
            <Breadcrumbs
              title="Academic Channels List"
              breadcrumbItems={breadcrumbItems}
            />

            <div
              style={{
                // display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}>
              {/* <div>
                <Button
                  color="danger"
                  className="waves-effect waves-light me-1"
                  onClick={() => handleDeleteAllData()}
                // disabled={issAllDeleteToastOpen}
                >
                  Delete All Data
                </Button>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <Button
                  color="primary"
                  className="waves-effect waves-light me-1"
                  onClick={handleExportClick}>
                  Export
                </Button>
                <Button
                  color="primary"
                  className="waves-effect waves-light me-1"
                  onClick={() => setIsModalOpen(true)}>
                  Import
                </Button>

                <Button
                  color="primary"
                  className="waves-effect waves-light me-1"
                  onClick={handleClickAdd}>
                  Create Academic Channels
                </Button>
              </div>
            </div>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    
    </React.Fragment>
  );
};

export default AcademicChannelsList;
